@font-face {
  font-family: "Geometria";
  src: url("fonts/Geometria-Regular.eot");
  src: local("Geometria Regular"), local("Geometria-Regular"),
    url("fonts/Geometria-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/Geometria-Regular.woff2") format("woff2"),
    url("fonts/Geometria-Regular.woff") format("woff"),
    url("fonts/Geometria-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Geometria";
  src: url("fonts/Geometria-Bold.eot");
  src: local("Geometria Bold"), local("Geometria-Bold"),
    url("fonts/Geometria-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/Geometria-Bold.woff2") format("woff2"),
    url("fonts/Geometria-Bold.woff") format("woff"),
    url("fonts/Geometria-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
body {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Geometria", sans-serif;
  font-size: 18px;
}
body,
html {
  height: 100%;
}
table {
  border-spacing: 0;
  border-width: 0;
}
table td,
table th {
  border-width: 0;
  border-width: 0px 0 1px;
  text-align: left;
  vertical-align: top;
  border-color: #ddd;
  padding: 8px;
}
.container {
  padding: 0 30px;
  height: 100%;
}
#root,
.wrapper {
  display: flex;
}
#root {
  flex-direction: column;
  height: 100%;
}
.wrapper-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 440px;
  width: 100%;
  padding: 24px 20px;
  background: #fff;
  box-shadow: 0 20px 8px -12px rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 5%);
  position: relative;
  overflow: hidden;
  margin: 200px auto 0;
}
header {
  display: flex;
  position: sticky;
  top: 0px;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), 0 1rem 1.5rem rgb(0 0 0 / 5%);
  margin: 0px -30px 0;
  padding: 16px 30px;
}
.title {
  font-size: 24px;
  line-height: 56px;
  color: #aaa;
}
.title-search {
  padding-left: 24px;
}
input {
  box-sizing: border-box;
  outline: none;
  padding: 0 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: "Geometria", sans-serif;
}

input::placeholder {
  color: #aaa;
}
.btn {
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 20px;
  outline: none !important;
}
.btn:hover {
  background-color: #ccc;
}
.search-order-input,
.auth-input {
  width: 100%;
  font-size: 24px;
  line-height: 56px;
}
.search {
  flex-grow: 1;
}
.order-images div {
  /* display: inline-block; */
  margin-right: 10px;
}

.order-images div img {
  width: 100px;
  display: block;
}
.order-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.product-accessories {
  display: inline-block;
}

/* admin */
.admin-users {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.admin-users .form-add-user {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.admin-users .user-item {
}

.button-change-password {
  border: none;
  width: 280px;
  height: 40px;
  cursor: pointer;
  margin: 0;
  text-align: left;
}
.admin-users input {
  padding: 10px;
}
.admin-users .form-add-user {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px -20px 0;
}
.admin-users .form-add-user label {
  margin: 0 20px;
}
